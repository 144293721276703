import React from "react"
import BottleIcon from "../../images/bottle.svg"
export const BottleIcons = ({ all = 4, free = 2 }) => {
  const height = 20
  const width = 15
  var items = Array(all)
    .fill(0)
    .map((a, i) => i)
    .map(i => (i < all - free ? "gray" : "green"))

  return (
    <div className="" style={{ marginLeft: 0 }}>
      {items.map((i, index) => (
        <BottleIcon
          key={index}
          fill={i}
          className="logo"
          width={width}
          height={height}
        />
      ))}
    </div>
  )
}
