import React from "react"
import { BottleIcons } from "./BottleIcons"
import Img from "gatsby-image"
import { Link } from "gatsby"
import "./Offer.css"

export const OfferImage = ({ offer }) => (
  <Img fluid={offer.fluidImage.childImageSharp.fluid} />
)

export const Offer = ({ offer, preview = true }) => {
  return (
    <div className="offer row align-items-center py-4">
      <div className="col-5 col-sm-5 col-md-4 offset-md-0 offset-lg-1 col-lg-3 col-xl-3 offset-xl-1">
        <OfferImage offer={offer} />
      </div>
      <div className="col-7 col-sm-7 col-md-8 col-lg-7">
        <OfferTitle offer={offer} />
        <p className="offer-description">{offer.description}</p>
        <BottleInfo offer={offer} linkToStore={true} />
        <hr />
        <SampleInfo offer={offer} />
        <BottleIcons all={offer.samples.all} free={offer.samples.free} />
      </div>
    </div>
  )
}

export const OfferBanner = ({ offer }) => {
  return (
    <div className="offer row align-items-center py-4">
      <div className="col-5 col-sm-5 col-md-4 col-lg-5 col-xl-4 ">
        <Link to={`/offers/${offer.slug}`}>
          <OfferImage offer={offer} />
        </Link>
      </div>
      <div className="col-7 col-sm-7 col-md-8 col-lg-7 col-xl-8">
        <OfferTitle offer={offer} />
        <p className="offer-description">{offer.description}</p>
        <BottleInfo offer={offer} />
        <hr />
        <SampleInfo offer={offer} />
        <BottleIcons all={offer.samples.all} free={offer.samples.free} />
        <div className="mt-3 mb-3">
          <Link
            className="btn btn-outline-dark btn-lg1"
            to={`/offers/${offer.slug}`}
          >
            Sample reservieren
          </Link>
        </div>
      </div>
    </div>
  )
}

export const OfferPreview = ({ offer }) => (
  <div className="offer offer-preview row align-items-center py-4 ">
    <div className="col-5 col-sm-5 col-md-4 col-lg-5 offset-md-1 offset-lg-0 col-xl-4">
      <Link to={`/offers/${offer.slug}`}>
        <Img fluid={offer.fluidImage.childImageSharp.fluid} />
      </Link>
    </div>
    <div className="col-7 col-sm-7 col-md-7 col-lg-7">
      <OfferTitle offer={offer} />
      <BottleInfo offer={offer} />
      <SampleInfo offer={offer} />

      <div className="mt-3 mb-3">
        <Link
          className="btn btn-outline-dark btn-lg1"
          to={`/offers/${offer.slug}`}
        >
          Sample reservieren
        </Link>
      </div>
    </div>
  </div>
)

const getSamplePrice = offer => {
  var tp = offer.bottle.price + offer.bottle.deliveryPrice

  var p_ml = tp / offer.bottle.sizeMl
  var sample_price = p_ml * offer.sample.sizeMl
  sample_price = Math.round(sample_price * 2) / 2
  return sample_price.toFixed(2)
}

const getLiterPrice = offer => {
  var tp = offer.bottle.price + offer.bottle.deliveryPrice
  var p_ml = tp / offer.bottle.sizeMl
  var sample_price = p_ml * 1000
  sample_price = Math.round(sample_price * 2) / 2
  return sample_price.toFixed(2)
}

export const BottleInfo = ({ offer, linkToStore = false }) => (
  <div className="mt-3">
    <div className="subheader">
      <ExternalLink href={offer.link} active={linkToStore}>
        Ganze Flasche ({offer.bottle.size}) bei Amazon
      </ExternalLink>
    </div>

    <div className="price-line ">
      <div className="price-sm">{offer.bottle.price}&nbsp;€</div>
      <div className="pl-2 price-fee">
        + {offer.bottle.deliveryPrice}€ Versand
      </div>
    </div>
    <div className="price-liter">{getLiterPrice(offer)}€ / 1000ml</div>
  </div>
)

const ExternalLink = ({ children, active = false, href }) => {
  if (active) return <a href={href}>{children}</a>
  return <span>{children}</span>
}

export const SampleInfo = ({ offer }) => (
  <div className="mt-3">
    <div className="subheader">
      Sample {offer.sample.size} - <b>Noch {offer.samples.free} verfügbar!</b>
    </div>

    <div className="price-line ">
      <div className="price-lg">{getSamplePrice(offer)}&nbsp;€</div>
      <div className="pl-2 price-fee">+ {offer.sample.fee}€ Servicefee</div>
    </div>
  </div>
)

export const OfferTitle = ({ offer }) => (
  <h2>
    {offer.name}
    <br />
    <small>{offer.teaser}</small>
  </h2>
)
