import React, { useLayoutEffect, useEffect, useState } from "react"

import "./page.css"

import Logo from "../../images/bottlebird.svg"

import Footer from "./footer"
import { Link } from "gatsby"
import SEO from "../seo"

import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"

export default ({ title = null, children, bigLogo = false }) => {
  var [transparentNav, setTransparentNav] = useState(true)

  return (
    <>
      <Header isScrolled={!transparentNav} bigLogo={bigLogo} />
      <SEO title={title} />
      <div style={{}}>{children}</div>
      <Footer />
    </>
  )
}

const Header = ({ isScrolled = false, bigLogo = false }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        {bigLogo ? <BigLogo /> : <SmallLogo />}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" />
          <ul className="navbar-nav ml-4">
            <li className="nav-item ">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>

            <li className="nav-item ">
              <Link className="nav-link" to="/rules">
                So funktioniert's
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="nav-link" to="/offers">
                Flaschenteilungen
              </Link>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#">
                Log in
              </a>
            </li>
          </ul>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

const HeaderOld = ({ isScrolled = false, bigLogo = false }) => {
  var cls = isScrolled
    ? " shadow-sm bg-light navbar-light"
    : " navbar-transparent navbar-dark bg-dark"

  var l = isScrolled
    ? "https://www.staedteherz.de/themes/Frontend/Stdhmedien/frontend/_public/src/svg/logo_sidebar.svg" //logo
    : "https://www.staedteherz.de/themes/Frontend/Stdhmedien/frontend/_public/src/svg/logo_sidebar.svg"

  return (
    <nav className={`navbar navbar-expand-lg bg-light navbar-light`}>
      <div className="container">
        {bigLogo ? <BigLogo /> : <SmallLogo />}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse " id="navbarNav">
          <div className="mr-auto" />
          <ul className="navbar-nav ml-4">
            <li className="nav-item ">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>

            <li className="nav-item ">
              <Link className="nav-link" to="/rules">
                So funktioniert's
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="nav-link" to="/offers">
                Flaschenteilungen
              </Link>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="#">
                Log in
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

const BigLogo = () => (
  <>
    <div className="d-none d-lg-block ">
      <div className="bb-brand">
        <div className="bb-brand-logo">
          <Link to="/">
            <Logo
              className="mt-0"
              height="100"
              width="130"
              alt=""
              style={{ zIndex: 1000 }}
            />
          </Link>
        </div>
      </div>
    </div>

    <div className="d-lg-none ">
      <div className="bb-brand-sm ">
        <div className="bb-brand-logo-sm">
          <Link to="/">
            <Logo
              className="mt-0"
              height="60"
              width="100"
              alt=""
              style={{ zIndex: 1000 }}
            />
          </Link>
        </div>
      </div>
    </div>
  </>
)

const SmallLogo = () => (
  <div className="bb-brand-sm ">
    <div className="bb-brand-logo-sm">
      <Link to="/">
        <Logo
          className="mt-0"
          height="60"
          width="100"
          alt=""
          style={{ zIndex: 1000 }}
        />
      </Link>
    </div>
  </div>
)
