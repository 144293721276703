import React, { useState } from "react"
import { Form } from "react-final-form"
import { Field } from "react-final-form-html5-validation"
import { sendToTelegram } from "./GetInvite"

export const TipBlock = ({}) => (
  <div className="bg-white py-5">
    <Tips />
  </div>
)

export const Tips = ({ page = null }) => {
  const [email, setEmail] = useState(null)

  const onSubmitHandler = values => {
    console.log("values", values)

    fetch(
      "https://firestore.googleapis.com/v1/projects/bottlebird-c9d8b/databases/(default)/documents/Prospects",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            email: { stringValue: values.email },
            source: { stringValue: "tip" },
            page: { stringValue: "" + page },
            tip: { stringValue: "" + values.tip },
          },
        }),
      }
    ).then(() => setEmail(values.email))

    sendToTelegram(`tip: ${values.tip} from  ${values.email}`)
  }

  return (
    <div className="container">
      <ActionBlock>
        {!email && (
          <h3 className="text-center">
            Welcher Whisky willst Du probieren?
            <br />
            Hasst Du einen Tipp für uns?
          </h3>
        )}

        {email && (
          <h3 className="text-center">
            Danke!
            <br />
            Hasst Du noch einen Tipp?
          </h3>
        )}

        <Form
          onSubmit={onSubmitHandler}
          initialValues={{ email: email, tip: "" }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} className="mb-0">
              <div className="form-row my-3">
                <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <Field
                    name="tip"
                    component="input"
                    type="text"
                    className="form-control"
                    placeholder="Whisky Name oder Web-Adresse"
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-8 col-sm-8 mb-2">
                  <Field
                    name="email"
                    component="input"
                    type="email"
                    className="form-control"
                    placeholder="Deine Email-Adresse"
                    required
                  />
                </div>
                <div className="offset-lg-4 col-lg-4 col-md-4 col-sm-4">
                  <button
                    type="submit"
                    className="btn btn-outline-success form-control"
                  >
                    Abschicken
                  </button>
                </div>
              </div>
            </form>
          )}
        />
        <div className="text-center">
          Wir melden uns, soblad dein Wunsch-Whisky da ist.
        </div>
      </ActionBlock>
    </div>
  )
}

const ActionBlock = ({ children }) => {
  return (
    <div className="mt-5 row">
      <div
        className="offset-lg-2 col-lg-8  offset-md-1 col-md-10 col-sm-12 bg-light p-4 text-center"
        style={{
          borderLeft: "18px solid #c6383b",
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  )
}

export const TipsOld = ({ page = null }) => {
  const [email, setEmail] = useState(null)

  const onSubmitHandler = values => {
    console.log("values", values)

    fetch(
      "https://firestore.googleapis.com/v1/projects/bottlebird-c9d8b/databases/(default)/documents/Prospects",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            email: { stringValue: values.email },
            source: { stringValue: "tip" },
            page: { stringValue: "" + page },
            tip: { stringValue: "" + values.tip },
          },
        }),
      }
    ).then(() => setEmail(values.email))
  }

  return (
    <div className="container">
      <div className="py-5 row">
        <div
          style={{ borderTop: "18px solid #c6383b" }}
          className="col  mt-5 bg-light p-5"
        >
          {!email && (
            <h3 className="text-center">
              Welcher Whisky willst Du probieren?
              <br />
              Hasst Du einen Tipp für uns?
            </h3>
          )}

          {email && (
            <h3 className="text-center">
              Danke!
              <br />
              Hasst Du noch einen Tipp?
            </h3>
          )}

          <Form
            onSubmit={onSubmitHandler}
            initialValues={{ email: email, tip: "" }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-row my-3">
                  <div className="col">
                    <Field
                      name="tip"
                      component="input"
                      type="text"
                      className="form-control"
                      placeholder="Whisky Name oder Link"
                      required
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      className="form-control"
                      placeholder="Deine Email-Adresse"
                      required
                    />
                  </div>
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-outline-success form-control"
                    >
                      Abschicken
                    </button>
                  </div>
                </div>
              </form>
            )}
          />
          <div className="text-center">
            Wir melden uns, soblad dein Wunsch-Whisky da ist.
          </div>
        </div>
      </div>
    </div>
  )
}
