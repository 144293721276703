import React from "react"
import { OfferBanner } from "../offers/Offer"

import Lines from "../../images/lines2.svg"

export const MainBanner = ({ offer }) => (
  <div className="bg-light ">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-4 col-md-9 col-sm-12 col-12">
          <div className="d-lg-none pb-4 "></div>
          <h1>
            Hast Du <i className="banner-den">den</i> Whisky probiert?
          </h1>{" "}
          <div className="header-subline">
            Entdecke edle Whiskys, ohne die ganze Flasche zu kaufen. Wir
            organisieren die Flaschenteilung.
          </div>
          <div className="d-lg-none pb-4 "></div>
        </div>
        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
          <OfferBanner offer={offer} />
        </div>
      </div>
    </div>

    <div style={{ height1: 60, transform1: "scaleX(-1)" }}>
      <Lines />
      {/* <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1440 100"
      >
        <path
          class="st0"
          d="M685.6,38.8C418.7-11.1,170.2,9.9,0,30v96h1440V30C1252.7,52.2,1010,99.4,685.6,38.8z"
          id="path4861"
          fill="#fec54e"
        />
        <path
          class="st0"
          d="m 684.17143,55.612656 c -266.9,-49.8999997 -515.4,-28.9 -685.6000026,-8.8 l 0,96.000004 1439.9999726,0 0,-96.000004 c -187.3001,22.2 -430,69.399994 -754.39997,8.8 z"
          id="path4861-6"
          fill="#ffffff"
        />
        />
   
      </svg> */}
    </div>
  </div>
)
