import React from "react"
import Page from "../components/page/page"

import { MainBanner } from "../components/home/MainBanner"
import Offers from "../components/offers/Offers"

import { graphql } from "gatsby"
import { HowItWorks, HowItWorksTitle } from "../components/HowItWorks"
import { Tips } from "../components/Tips"

const IndexPage = ({ data }) => {
  return (
    <Page title="Home" bigLogo={true}>
      <MainBanner offer={data.offersJson} />
      <HowItWorksBlock />
      <OffersBlock />
      <TipBlock />
    </Page>
  )
}

export default IndexPage

const OffersBlock = ({}) => (
  <div className="bg-light py-4">
    <div className="container my-4">
      <Offers />
    </div>
  </div>
)

const HowItWorksBlock = () => (
  <div className="bg-white py-4">
    <div className="container my-4">
      <HowItWorksTitle />
      <HowItWorks showDescription={false} page="home" />
    </div>
  </div>
)

const TipBlock = ({}) => (
  <div className="bg-white pt-2 pb-5">
    <Tips page="home" />
  </div>
)

export const pageQuery = graphql`
  query {
    offersJson(slug: { eq: "glenfarclas_25" }) {
      ...OfferFragment
    }
  }
`
