import React from "react"
import { Link } from "gatsby"
import "./footer.css"

import LogoGS from "../../images/logo_greenstones.svg"
import Logo from "../../images/bottlebird.svg"

const Footer = () => (
  <footer className="footer bg-light py-4">
    <div className="flips-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 mb-4">
            <Logo width="80" height="80" alt="" />
          </div>

          {/* <div className="col-md-3">
            <b>Greenstones GmbH</b> <br />
            info@greenstones.de
          </div> */}

          <div className="col-md-3 col-sm-6 col-12 mb-4">
            <b>Links</b> <br />
            <Link to="/">Home</Link>
            <br />
            <Link to="/rules">So funktioniert's</Link>
            <br />
            <Link to="/offers">Flaschenteilungen</Link>
          </div>

          <div className="col-md-3 col-sm-6 col-12 mb-4">
            <b>Rechtliches</b> <br />
            <Link to="/about">Über uns</Link>
            <br />
            <Link to="/impressum">Impressum</Link>
            <br />
            <Link to="/impressum">Datenschutz</Link>
          </div>
        </div>
      </div>

      {/* <Link to="/kontakt">Kontakt</Link>{" "} */}
    </div>
  </footer>
)

export default Footer
