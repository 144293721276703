import { graphql, Link, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { OfferPreview } from "./Offer"

const Offers = ({ offers }) => (
  <div className="row">
    {offers.map((o, i) => (
      <div className="col-sm-12 col-12 col-md-12 col-lg-6 mb-4" key={i}>
        <OfferPreview offer={o} />
      </div>
    ))}
  </div>
)

const OffersContainer = () => (
  <StaticQuery
    query={graphql`
      query {
        allOffersJson {
          edges {
            node {
              ...OfferFragment
            }
          }
        }
      }
    `}
    render={data => {
      const offers = data.allOffersJson.edges.map(edge => edge.node)
      return <Offers offers={offers} />
    }}
  />
)

export default OffersContainer
