import React, { useState } from "react"
import { Form } from "react-final-form"
import { Field } from "react-final-form-html5-validation"

const InviteForm = ({ page = null, onSubmit = () => {} }) => {
  const onSubmitHandler = values => {
    console.log("values", values)

    fetch(
      "https://firestore.googleapis.com/v1/projects/bottlebird-c9d8b/databases/(default)/documents/Prospects",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fields: {
            email: { stringValue: values.email },
            source: { stringValue: "invite" },
            page: { stringValue: "" + page },
          },
        }),
      }
    ).then(() => onSubmit())

    sendToTelegram(`invite: ${values.email}`)
  }

  return (
    <Form
      onSubmit={onSubmitHandler}
      initialValues={{ email: "" }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className="mt-2 mb-4 bg-white1">
          <div className="form-row ">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="input-group">
                <Field
                  name="email"
                  component="input"
                  type="email"
                  className="form-control"
                  placeholder="Deine Email-Adresse"
                  required
                />

                <div className="input-group-append">
                  <button
                    className="btn btn-outline-success"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Einladung holen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  )
}

export const GetInvite = ({ page = null }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  console.log("emailSubmitted", emailSubmitted)

  return (
    <div className=" mt-5 row">
      <div
        className="offset-lg-2 col-lg-8  offset-md-1 col-md-10 col-sm-12 bg-light p-4 text-center"
        style={{
          borderLeft: "18px solid #c6383b",
        }}
      >
        <div>
          <div>
            <h1 className="">{!emailSubmitted ? "Interessiert?" : "Danke!"}</h1>
          </div>
          {!emailSubmitted && (
            <div>
              <InviteForm
                page={page}
                onSubmit={() => setEmailSubmitted(true)}
              />
            </div>
          )}
          <p className="">Du bekommst eine Einladung, sobald wie starten.</p>
        </div>
      </div>
    </div>
  )
}

export const GetInviteOld = ({ page = null }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  console.log("emailSubmitted", emailSubmitted)

  return (
    <div
      className=" mt-5 p-4 mx-auto bg-light d-flex flex-column align-items-center"
      style={{ maxWidth: 680, borderLeft: "18px solid #c6383b" }}
    >
      <div className="d-flex ">
        <div>
          <h1 className=" mb-0 mr-2">
            {!emailSubmitted ? "Interessiert?" : "Danke!"}
          </h1>
        </div>
        {!emailSubmitted && (
          <div className=" pt-2">
            <InviteForm page={page} onSubmit={() => setEmailSubmitted(true)} />
          </div>
        )}
      </div>
      <p className="text-center mt-0 mb-0">
        Du bekommst eine Einladung sobald wie starten.
      </p>
    </div>
  )
}

export const GetInviteVertical = ({ page = null }) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  return (
    <div
      className=" ml-1 mt-5 p-4  bg-light d-flex flex-column "
      style={{ maxWidth: 680, borderLeft: "6px solid #c6383b" }}
    >
      <div>
        <h1 className=" mb-3 mr-2">
          {!emailSubmitted ? "Interessiert?" : "Danke!"}
        </h1>
      </div>

      {!emailSubmitted && (
        <div className="d-flex flex-row">
          <div className=" pt-2">
            <InviteForm page={page} onSubmit={() => setEmailSubmitted(true)} />
          </div>
        </div>
      )}
      <p className=" mt-0 mb-0">
        Du bekommst eine Einladung, sobald wie starten.
      </p>
    </div>
  )
}

export const sendToTelegram = msg => {
  fetch(
    `https://api.telegram.org/bot888290750:AAHziageik7h3sXj2MR8v8dRykco1E8GIyY/sendMessage?chat_id=152671962&text=${msg}`
  )
    .then(() => console.log("send to telegram"))
    .catch(e => console.error("can't send to telegram", e))
}
