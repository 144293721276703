import React from "react"
import { GetInvite } from "./GetInvite"
import { Link } from "gatsby"

const Step = ({
  index,
  children,
  circleSize = 120,
  offset = 0,
  showDescription = true,
}) => (
  <div className={`row ` + (showDescription ? "my-5" : "")}>
    <div className={`col-lg-6 col-md-6  offset-md-${offset}`}>
      <div className="d-flex align-items-center my-3">
        <div
          className="bg-light mr-3 my-2 flex-shrink-0"
          style={{
            width: circleSize,
            height: circleSize,
            textAlign: "center",
            borderRadius: "50%",
            lineHeight: `${circleSize}px`,
            color: "gray",
            fontSize: "4.4rem",
            fontWeight: "bold",
          }}
        >
          {index}
        </div>
        <div>
          <div
            className=""
            style={{ fontSize: "1.25rem", lineHeight: "1.6rem" }}
          >
            {children}
          </div>
          {/* {showDescription && (
            <div className="my-2 " style={{ color: "rgb(99, 116, 128)" }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </div>
          )} */}
        </div>
      </div>
    </div>
  </div>
)

const StepDescription = ({ children }) => (
  <div
    className="my-2 "
    style={{
      color: "rgb(99, 116, 128)",
      fontSize: "1rem",
      lineHeight: 1.5,
    }}
  >
    {children}
  </div>
)

export const HowItWorks = ({ showDescription = true, page = null }) => (
  <>
    <Step index={1} offset={2} showDescription={showDescription}>
      Wähle den Whisky aus, <br />
      dass Du probieren willst.
      <StepDescription>
        Wenn dein Wunsch-Whisky nicht bei aktuellen Teilungen aufgelistet ist,
        gib uns Bescheid und wir organisieren die Flaschenteilung.
      </StepDescription>
    </Step>

    <Step index={2} offset={5} showDescription={showDescription}>
      Sichere deine Probe bei der Flaschenteilung.
      <StepDescription>
        Der Sample-Preis wird so berechnet: der Einkaufspreis wird ohne
        Aufschlag durch alle Teilnehmer geteilt. Zusätzlich gibt es eine Gebühr
        für den Service, Verpackung und Versand.{" "}
      </StepDescription>
    </Step>

    <Step index={3} offset={2} showDescription={showDescription}>
      Sobald alle Proben reserviert sind, wird die Flasche gekauft und
      aufgeteilt.
      <StepDescription>
        Wir füllen die Miniaturflaschen unter strenger Einhaltung von
        Hygienevorschriften selbst ab. Die Flaschen sind neu, mit allen
        relevanten Angaben beschriftet und mit Drehverschluss aus Metall.
      </StepDescription>
    </Step>

    <Step index={4} offset={5} showDescription={showDescription}>
      Die Probe wird zu Dir nach Hause geschickt.
      <StepDescription>
        Versand ausschließlich innerhalb Deutschlands, per Maxibrief oder als
        Paket mit Haftung und Sendungsverfolgung.
      </StepDescription>
    </Step>

    <GetInvite page={page} />
  </>
)

export const HowItWorksTitle = ({ showTitle = true }) => (
  <h1
    className="text-center mb-4"
    style={{
      lineHeight: 1,
    }}
  >
    {showTitle && (
      <>
        <span>So funktioniert's</span>
        <br />
      </>
    )}

    <small
      style={{
        color: "rgb(99, 116, 128)",
        fontSize: "1rem",
        lineHeight: 1,
      }}
    >
      Du musst mindestens 18 Jahre alt sein. Versand nur innerhalb Deutschlands.
    </small>
  </h1>
)
